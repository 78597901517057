import type { MenuLink } from '$lib/domain/menu-link.interface';

export const services: MenuLink[] = [
		{
				text: 'Startup studio',
				url: '/software-development-for-startups/'
		},
		{
				text: 'Product design and development',
				url: '/product-development-services/'
		},
		{
				text: 'Platform Development',
				url: '/platform-development/'
		},
		{
				text: 'Technology Modernization',
				url: '/technology-modernization/',
		},
		{
				text: 'Team extension',
				url: '/team-extension-services/'
		},
		{
				text: 'IT support',
				url: '/support-and-maintenance/'
		}
];
export const expertises: MenuLink[] = [
		{
				text: 'Web development',
				url: '/web-development/'
		},
		{
				text: 'Mobile app development',
				url: '/mobile-development/'
		},
		{
				text: 'UI/UX design',
				url: '/ui-ux-design-services/'
		},
		{
				text: 'QA services',
				url: '/quality-assurance/'
		},
		{
				text: 'Business Application Development',
				url: '/enterprise-application-development-services/'
		},
];
export const industries: MenuLink[] = [
		{
				text: 'Healthcare',
				url: '/healthcare-software-development/'
		},
		{
				text: 'Wellness',
				url: '/fitness-wellness-development/'
		},
		{
				text: 'Logistics',
				url: '/logistics-software-development/'
		},
		{
				text: 'GreenTech',
				url: '/greentech-software-development/'
		},
		{
				text: 'Construction',
				url: '/construction-software-development/'
		},
		{
				text: 'Finance',
				url: '/finance-software-development/'
		},
		{
				text: 'Education',
				url: '/education-software-development-services/'
		},
		{
				text: 'Energy & Utilities',
				url: '/energy-and-utilities-software-development-services/'
		},
		{
				text: 'Media & Entertainment',
				url: '/media-and-entertainment-software-development/'
		},
];
export const solutions: MenuLink[] = [
		{
				text: 'Telemedicine',
				url: '/telemedicine-software-development/'
		},
		{
				text: 'Laboratory Information Management',
				url: '/laboratory-information-management-systems/'
		},
		{
				text: 'EHR and EMR',
				url: '/electronic-medical-records-software-development/'
		},
		{
				text: 'CRM for Healthcare',
				url: '/healthcare-crm-software/'
		},
		{
				text: 'Fleet Management',
				url: '/fleet-management-software-development-company/'
		},
		{
				text: 'Freight Forwarding',
				url: '/freight-forwarding-software-solutions/'
		},
		{
				text: 'Warehouse Management',
				url: '/warehouse-management-system-wms/'
		},
		{
				text: 'CRM for Logistics',
				url: '/crm-software-for-logistics/'
		},
		{
				text: 'Mobility',
				url: '/mobility-software-development/'
		},
];
